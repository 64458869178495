const projectSalesPhase = {
    RESERVE: "Reserve",
    WAITING_LIST: "Waiting List",
    BOOKING: "Booking",
    MORTGAGE: "Mortgage",
    LAWYER: "Lawyer",
    VACANT_POSSESSION_NOTICE: "Vacant Possession Notice",
    CANCELLED: "Cancel",
    COMPLETED: "Completed"
};

export default projectSalesPhase;
