const bank = {
    MAYBANK: "Maybank",
    CIMB: "CIMB Group Holdings",
    PUBLIC: "Public Bank Berhad",
    RHB: "RHB Bank",
    HONG_LEONG: "Hong Leong Bank",
    AMBANK: "AmBank",
    UOB: "UOB Malaysia",
    BANK_RAKYAT: "Bank Rakyat",
    OCBC: "OCBC Bank Malaysia",
    HSBC: "HSBC Bank Malaysia",
    BANK_ISLAM: "Bank Islam Malaysia",
    AFFIN: "Affin Bank",
    ALLIANCE: "Alliance Bank Malaysia Berhad",
    SC: "Standard Chartered Bank Malaysia",
    MBSB: "MBSB Bank Berhad",
    CITIBANK: "CitiBank Malaysia",
    BSN: "Bank Simpanan Nasional (BSN)",
    AGROBANK: "Agrobank"
};

export default bank;
