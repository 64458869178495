const orientation = {
    EAST: "East",
    SOUTHEAST: "Southeast",
    NORTHEAST: "Northeast",
    SOUTH: "South",
    NORTH: "North",
    WEST: "West",
    SOUTHWEST: "Southwest",
    NORTHWEST: "Northwest"
};
export function getOrientationShorthand(orien) {
    switch (orien) {
        case orientation.EAST:
            return "E";
        case orientation.SOUTHEAST:
            return "SE";
        case orientation.NORTHEAST:
            return "NE";
        case orientation.SOUTH:
            return "S";
        case orientation.NORTH:
            return "N";
        case orientation.WEST:
            return "W";
        case orientation.SOUTHWEST:
            return "SW";
        case orientation.NORTHWEST:
            return "NW";
        default:
            throw "Orientation is not valid";
    }
}
export default orientation;
