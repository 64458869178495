const projectLeadStatus = {
    PENDING: "Pending Doc Review",
    IN_PROGRESS: "Submitted",
    APPROVED: "Approved",
    REJECTED: "Rejected",
    DECLINED: "Request Additional Doc",
    LOAN_CONFIRMED: "Acceptance",
    OTHER: "Other",
    CANCELLED: "Cancelled",
    SIGNED_LO: "Signed LO"
};

const activeStatus = [
    projectLeadStatus.PENDING,
    projectLeadStatus.IN_PROGRESS,
    projectLeadStatus.APPROVED,
    projectLeadStatus.DECLINED,
    projectLeadStatus.LOAN_CONFIRMED
];
const historyStatus = [
    projectLeadStatus.SIGNED_LO,
    projectLeadStatus.REJECTED,
    projectLeadStatus.CANCELLED,
    projectLeadStatus.OTHER
];
const getLabel = (status) => {
    switch (status) {
        case projectLeadStatus.PENDING:
            return "Pending Document Review";
        case projectLeadStatus.IN_PROGRESS:
            return "Submitted";
        case projectLeadStatus.APPROVED:
            return "Approved";
        case projectLeadStatus.DECLINED:
            return "Requested For Resubmit";
        case projectLeadStatus.LOAN_CONFIRMED:
            return "Loan Accepted";
        case projectLeadStatus.SIGNED_LO:
            return "Succeed";
        case projectLeadStatus.REJECTED:
            return "Rejected";
        case projectLeadStatus.CANCELLED:
            return "Cancelled";
        case projectLeadStatus.OTHER:
            return "Resolved By Other";

        default:
            throw `Status "${status}" does not exist.`;
    }
};

export default projectLeadStatus;
export { activeStatus, historyStatus, getLabel };
