import areaUnitType from "@/enums/areaUnitType";

const areaUnitTypeAbbr = {
    SQUARE_FEET: "sqft",
    SQUARE_METER: "sqm",
    HECTARE: "ha",
    ACRE: "ac"
};

export function getAreaUnitTypeAbbr(type) {
    switch (type) {
        case areaUnitType.ACRE:
            return areaUnitTypeAbbr.ACRE;
        case areaUnitType.HECTARE:
            return areaUnitTypeAbbr.HECTARE;
        case areaUnitType.SQUARE_FEET:
            return areaUnitTypeAbbr.SQUARE_FEET;
        case areaUnitType.SQUARE_METER:
            return areaUnitTypeAbbr.SQUARE_METER;

        default:
            throw `Area unit type "${type}" does not exist`;
    }
}

export default areaUnitTypeAbbr;
