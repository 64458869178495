<template>
  <div class="manage-project-vacant-possesion-detail">
    <div class="container fluid">
      <h3 class="m-2">Vacant Possession Detail</h3>

      <!-- Sales Related -->
      <div class="bordered-card p-2 mb-2">
        <div class="row">
          <display-data
            :class="displayCSS"
            label="SPA Date"
            :content="
              $moment(
                vp.projectPurchaseLawyer.signSalesPurchaseAgreementDate
              ).format('DD-MM-YYYY')
            "
          ></display-data>
          <display-data
            :class="displayCSS"
            label="SPA Stamp Duty Date"
            :content="
              $moment(
                vp.projectPurchaseLawyer
                  .salesPurchaseAgreementStampDutyIsPaidDate
              ).format('DD-MM-YYYY')
            "
          ></display-data>
        </div>
        <div class="row justify-content-end">
          <fd-button class="main" @click="completeVP"> Complete VP </fd-button>
        </div>
      </div>

      <!-- BUYER -->
      <div class="bordered-card p-2 mb-2">
        <h4 class="mb-3">Buyer</h4>

        <div class="row">
          <display-data
            :class="displayCSS"
            label="Buyer Name"
            :content="reservation.purchaserName1"
          ></display-data>
          <display-data
            :class="displayCSS"
            label="IC/Passport"
            :content="reservation.purchaserIdentityCardOrPassport1"
          ></display-data>
          <display-data
            :class="displayCSS"
            label="Gender"
            :content="reservation.gender1"
          ></display-data>
          <display-data
            :class="displayCSS"
            label="Nationality"
            :content="reservation.nationality1"
          ></display-data>
          <display-data
            :class="displayCSS"
            label="Contact Number"
            :content="reservation.phoneContact"
          ></display-data>
          <display-data
            :class="displayCSS"
            label="Email"
            :content="reservation.email"
          ></display-data>
        </div>
      </div>

      <!-- AGENT -->
      <agent-info
        v-if="vp.agent"
        class="bordered-card p-2 mb-2"
        :agencyUser="vp.agent"
      ></agent-info>

      <!-- PROJECT -->
      <div class="bordered-card p-2 mb-2">
        <h4 class="mb-3">Project</h4>

        <div class="row">
          <display-data
            :class="displayCSS"
            label="Project Name"
            :content="vp.project.name"
          ></display-data>
          <display-data
            :class="displayCSS"
            label="Property Type"
            :content="reservation.propertyType"
          ></display-data>
          <display-data
            :class="displayCSS"
            label="Plot Number"
            :content="reservation.unitPlot"
          ></display-data>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    DisplayData: () => import("@/components/GlobalComponents/DisplayData"),
    AgentInfo: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/General/AgentInfo"
      )
  },
  mixins: [],
  props: {
    isAssigned: {
      type: Boolean,
      default: false
    },
    getVPDetailAPI: {
      type: Function,
      required: true
    },
    completeVPAPI: {
      type: Function,
      required: true
    },
    listingRouteName: {
      type: String,
      required: true,
      default: "ManageProjectVacantPossession"
    }
  },
  data: function () {
    return {
      vp: {},
      reservation: {},

      displayCSS: "col-12 sm-col-6 md-col-4 lg-col-3"
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.$store.commit("setIsLoading", true);
        await this.getVacantPossesion();
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
      }
    },
    // ============================== API RELATED ==============================
    async getVacantPossesion() {
      this.isLoading = true;
      try {
        let data = await this.getVPDetailAPI(this.$route.params.id);

        this.vp = this._.cloneDeep(data);
        this.reservation = this._.cloneDeep(data.projectPurchaseReservation);
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get vacant possession data. Please try again later."
        });
      }
    },
    async completeVP() {
      let confirm = await this.$confirm({
        title: "Complete Vacant Possession",
        message:
          "Are you sure? This will complete the sales process as this is the last step for it."
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await this.completeVPAPI(this.$route.params.id);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: "Vacant possession has beeen completed successfully."
          });

          this.$store.commit("setIsLoading", false);

          this.$router.push({ name: this.listingRouteName });
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to complete vacant possession. Please try again later."
          });
        }
      }
    }
  }
};
</script>

<style lang="scss">
</style>
