const projectLawyerClientStatus = {
    // LA
    LA_PENDING: "LA Pending",
    LA_CONFIRMED: "LA Confirmed",
    LA_SIGNED: "LA Signed",
    LA_STAMP_DUTY_PAID: "LA Stamp Duty Paid",
    LA_WITHOUT_LA: "Without LA",
    // SPA
    SPA_PENDING: "SPA Pending",
    SPA_CONFIRMED: "SPA Confirmed",
    SPA_SIGNED: "SPA Signed",
    SPA_STAMP_DUTY_PAID: "SPA Stamp Duty Paid"
};

export default projectLawyerClientStatus;
