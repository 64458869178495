<template>
  <div class="manage-project-sales-vp">
    <div class="container fluid">
      <vacant-possession-detail
        v-if="!_.isEmpty(API)"
        :getVPDetailAPI="API.getVPDetail"
        :completeVPAPI="API.completeVP"
        :listingRouteName="getListingRouteName()"
      ></vacant-possession-detail>
    </div>
  </div>
</template>

<script>
import VacantPossessionDetail from "@/components/GlobalComponents/Shared/ProjectSales/VacantPossession/VacantPossessionDetail";
import { projectVacantPossession as vpAPI } from "@/api";

export default {
  components: {
    VacantPossessionDetail
  },
  mixins: [],
  props: {
    isAssigned: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      vpAPI,

      API: {}
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.initAPI();
  },
  methods: {
    initAPI() {
      this.API = vpAPI.getProjectVacantPossessionAPI(this.isAssigned);
    },
    getListingRouteName() {
      return this.isAssigned
        ? "ManageAssignedProjectVacantPossession"
        : "ManageProjectVacantPossession";
    }
  }
};
</script>

<style lang="scss">
</style>
