const projectUnitStatus = {
    ACTIVE: "Active",
    INACTIVE: "Inactive",
    SOLD: "Sold",
    BOOKED: "Booked",
    RESERVATION: "Reservation",
    DELETED: "Deleted",
    CONFIRMED: "Confirmed",
    CLOSED: "Closed"
};

export default projectUnitStatus;
