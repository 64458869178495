const projectStatus = {
    NEW_LAUNCHED: "New Launched",
    UNDER_CONSTRUCTION: "Under Construction",
    PARTIALLY_COMPLETED: "Partially Completed",
    COMPLETED: "Completed",
    COMING_SOON: "Coming Soon",
    CLOSE: "Close"
};

export default projectStatus;
